import React from 'react';

export const IconResources = {
    TICK: {
        format: 'data',
        title: 'tick-icon',
        viewBox: '0 0 24 24',
        path: 'M19.3 5.3L9 15.6l-4.3-4.3-1.4 1.4 5 5 .7.7.7-.7 11-11z'
    },
    CloudUpload: {
        format: 'markup',
        title: 'upload-icon',
        viewBox: '0 0 344 344',
        markup: (
            <g fill='#C0C0C0'>
                <path d='M152.7 58.5a109 109 0 00-71 47.2c-3.6 5.5-6.7 9.3-7.7 9.3a86.3 86.3 0 00-72.1 65.7c-1.8 7.5-2 10.9-1.6 22.8a69.1 69.1 0 008.1 35 85.6 85.6 0 0057.1 46.3c5.4 1.5 16.2 1.7 113.5 1.7h107.5l8-2.7c11.6-4 19.9-9.2 29-18.3a67.4 67.4 0 0018.5-32.1 84.6 84.6 0 000-36.8 71 71 0 00-56.4-52c-4.1-.7-7.2-1.8-7.7-2.7-.3-.7-1.7-4.8-2.9-8.9a107.8 107.8 0 00-75.8-72.7 132.6 132.6 0 00-46.5-1.8zm54.8 92.5l35 35H200v57h-56v-57h-42.5l35-35a1458 1458 0 0135.5-35c.3 0 16.3 15.7 35.5 35z' />
            </g>
        )
    },
    DELETE: {
        format: 'markup',
        title: 'delete-icon',
        viewBox: '0 0 48 48',
        markup: (
            <g >
                <path fill='#F44336' d='M42,37c0,2.762-2.238,5-5,5H11c-2.762,0-5-2.238-5-5V11c0-2.762,2.238-5,5-5h26c2.762,0,5,2.238,5,5V37z' />
                <path fill='#FFEBEE' d='M21.914 12.065H25.914V36.107H21.914z' transform='rotate(-134.999 23.914 24.086)' />
                <path fill='#FFEBEE' d='M22.064 11.726H26.064V35.897H22.064z' transform='rotate(134.999 24.064 23.812)' />
            </g>
        )
    },
    UNDO: {
        format: 'markup',
        title: 'undo-icon',
        viewBox: '0 0 1024 1024',
        markup: (
            <g >
                <path fill="#00BCD4" d="M106.667 384l298.666-249.6v499.2z" />
                <path fill="#00BCD4" d="M597.333 298.667h-256v170.666h256C657.067 469.333 704 516.267 704 576s-46.933 106.667-106.667 106.667h-64v170.666h64c153.6 0 277.334-123.733 277.334-277.333S750.933 298.667 597.333 298.667z" />
            </g>
        )
    },
};

export default IconResources;
