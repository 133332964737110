import React from 'react';
import Select, { components } from 'react-select';
import GroupOptionModel from './GroupOptionModel';
import OptionModel from './OptionModel';
import IconResources from '../Icon/IconResources'
import Icon from '../Icon/Icon'

const groupOptionStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 100,
    color: '#aaa',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: 1.5,
    cursor: 'pointer',
};

const optionStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 100,
    marginLeft: 10,
    color: 'black',
    fontSize: '14px',
    lineHeight: 1.3,
};

const selectStyles = {
    control: (base): JSX.ElementClass => {
        return ({ ...base, minWidth: 'unset' });
    },
    menu: (base): JSX.ElementClass => {
        return ({ ...base, zIndex: 1100 });
    },
    option: (base): JSX.ElementClass => {
        return ({
            ...base,
            cursor: 'pointer',
            backgroundColor: 'transparent',
            '&:hover': { backgroundColor: '#f5f5f5' }
        });
    },
};

const CustomOption = ({ children, isSelected, ...props }): React.ReactNode => {
    return (
        <components.Option {...props}  >
            <div style={optionStyle}>
                <span>{children}</span>
                {isSelected && <Icon size={17} icon={IconResources.TICK} />}
            </div>
        </components.Option>
    );
};

const CustomValueContainer = ({ children, selectProps, ...props }): React.ReactNode => {
    const maxToShow = selectProps.badgeShowMaxSelectionNumber;
    const valueLength = selectProps.value.length;
    const shouldBadgeShow = valueLength > maxToShow;
    return (
        <components.ValueContainer {...props}>
            {shouldBadgeShow && `${valueLength} Items selected`}
            {children}
        </components.ValueContainer >
    );
};

const CustomMultiValueContainer = ({ children, selectProps, ...props }): React.ReactNode => {
    const maxToShow = selectProps.badgeShowMaxSelectionNumber;
    const valueLength = selectProps.value.length;
    const shouldBadgeShow = valueLength > maxToShow;
    return shouldBadgeShow
        ? null
        : (<components.MultiValueContainer {...props}>{children}</components.MultiValueContainer>);
};

interface Props {
    isMulti: boolean;
    closeMenuOnSelect: boolean;
    isLoading: boolean;
    isDisabled: boolean;
    placeholder: string;
    className: string;
    classNamePrefix: string;
    handleChange: Function;
    onMenuClose: Function;
    allowSelectAll: boolean;
    options: GroupOptionModel[];
    selected: string[] | string;
    badgeShowMaxSelectionNumber: number;
}

export class VulcanGroupSelect extends React.Component<Props, {}> {
    private selectAllOption = new OptionModel({ label: 'Select all', value: '*' });

    public static defaultProps: Partial<Props> = {
        isMulti: false,
        closeMenuOnSelect: false,
        isLoading: false,
        isDisabled: false,
        allowSelectAll: false,
        badgeShowMaxSelectionNumber: 3,
        selected: [],
    };

    private get SelectedOptions(): OptionModel[] {
        const { isMulti, options, selected } = this.props;
        const selectedArray = isMulti ? selected : [selected];
        const allOptions = ([] as OptionModel[]).concat(...(options.map(g => g.options))).filter(o => o.value !== this.selectAllOption.value);
        return allOptions.filter(o => selectedArray.includes(o.label));
    }

    public render(): JSX.Element {
        const { isMulti, allowSelectAll, options } = this.props;

        const allGroupOptions = isMulti && allowSelectAll && options.length > 1
            ? [this.selectAllOption, ...options]
            : options;

        return (
            <Select
                {...this.props}
                formatGroupLabel={this.formatGroupLabel}
                hideSelectedOptions={false}
                onChange={this.handleSelectChange}
                options={allGroupOptions}
                value={this.SelectedOptions}
                components={{ Option: CustomOption, ValueContainer: CustomValueContainer, MultiValueContainer: CustomMultiValueContainer }}
                styles={selectStyles}
            />
        );
    }

    private groupClick = (data: GroupOptionModel): void => {
        const selectedOption = this.SelectedOptions;
        const groupOption = data.options;

        const groupAlreadySelected = groupOption.every(o => selectedOption.map(so => so.label).includes(o.label));
        let newSelectOption: OptionModel[] = [];
        if (groupAlreadySelected === true) {
            newSelectOption = selectedOption.filter(so => !groupOption.map(o => o.label).includes(so.label));
        } else {
            const combineOptions = selectedOption.concat(groupOption);
            combineOptions.map(o => {
                if (newSelectOption.every(so => so.label !== o.label)) {
                    newSelectOption.push(o);
                }
            });
        }

        this.props.handleChange(newSelectOption);
    }

    private formatGroupLabel = (data: GroupOptionModel): React.ReactNode => (
        <div style={groupOptionStyle} onClick={(): void => this.groupClick(data)}>
            <span>{data.label}</span>
        </div>
    )

    private handleSelectChange = (selectedOptions: OptionModel[] | OptionModel): void => {
        const { handleChange, isMulti, allowSelectAll, options } = this.props;

        if (isMulti === true) {
            const selected = selectedOptions as OptionModel[];

            const allOptions = ([] as OptionModel[]).concat(...(options.map(g => g.options))).filter(o => o.value !== this.selectAllOption.value);
            if (allowSelectAll && allOptions.length > 0 && selected.some(s => s.value === this.selectAllOption.value)) {
                const alreadySelectAll = selected.filter(o => o.value !== this.selectAllOption.value).length === allOptions.length;
                if (alreadySelectAll) {
                    handleChange([]);
                } else {
                    handleChange(allOptions);
                }
            } else {
                handleChange(selected);
            }
        } else {
            const selected = selectedOptions as OptionModel;
            handleChange(selected);
        }
    }
}

export default VulcanGroupSelect;
