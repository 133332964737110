
import React from 'react';

interface Props {
    icon: any,
    color?: string,
    size?: number,
    className?: string
}

const Icon = ({ ...props }: Props) => {
    const { icon, color, size, className } = props;
    const styles = {
        svg: {
            display: 'inline-block',
            verticalAlign: 'middle',
        },
        path: {
            fill: color || 'currentColor',
        },
    };

    const iconContent = icon.format === 'data'
        ? <path style={styles.path} d={icon.path} />
        : icon.markup;

    return (
        <svg
            style={styles.svg}
            className={className || ''}
            width={`${size || 24}px`}
            height={`${size || 24}px`}
            viewBox={icon.viewBox || '0 0 24 24'}
        >
            {iconContent}
        </svg>
    );
};

export default Icon;
